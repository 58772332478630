<template>
  <v-container>
    <v-row>
      <v-col>
        <p class="text-h6 mb-3">
          Esta web no tiene un motivo comercial, <span class="secondary--text">no queremos comprar ni vender</span> en este espacio.
        </p>
        <p class="text-h6 mb-3">
          <span class="secondary--text">Solo compartir</span>, hacer más fuerte a nuestra competencia para que nos hagan más fuertes ellos a
          nosotros también.
        </p>
        <p class="text-h6 mb-3">
          <span class="secondary--text">Aquí podéis encontrar lo que nos ha funcionado en estos años</span>, ojalá podamos ayudar a alguna
          microfinanciera en algún lugar del mundo. Si lo conseguimos, el esfuerzo en este proyecto de transparencia TOTAL habrá tenido
          sentido.
        </p>
        <p class="text-h6 mb-3">
          Antonio Escohotado dijo una vez que <span class="secondary--text">competir es la forma suprema de cooperar.</span> Amén, maestro.
        </p>
      </v-col>
      <v-col cols="12" class="text-center">
        <v-btn rounded color="primary" x-large class="black--text px-10" @click="$router.push('/por_que_mujeres')">
          <b>¡VAMOS ALLÁ!</b>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Home",
  created() {
    if (this.$vuetify.breakpoint.smAndDown) {
      this.$emit("setImageBg", {
        backgroundImage: "url(" + require("@/assets/images/backgrounds/bgmobile-home.png") + ")"
      });
    } else {
      this.$emit("setImageBg", {
        backgroundImage: "url(" + require("@/assets/images/backgrounds/bg-home.png") + ")"
      });
    }
  }
};
</script>
